<template>
  <div class="share-list contain">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="contact-div"
    >
      <div class="contact-dev-left">
        <img src="@/assets/img/kakao.png">
      </div>
      <div class="contact-dev-right">
        <div class="contact-dev-right-top">
          <div class="contact-dev-right-top-top">
            {{ item.title }}
          </div>
          <a
            class="contact-dev-right-top-bottom firebaseClickEvent"
            :href="item.link"
          >{{ item.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Kakao',
  data () {
    return {
      list: [
        {
          title: '공식 카카오방',
          link: 'https://bit.ly/3hHo5Ze'
        }
      ]
    }
  }
}
</script>

<style lang="less" >
  @import "~@/assets/styles/share-list.less";
  .contain{
    background-color: #F4F4F4;
  }
</style>
